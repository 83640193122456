import {EQLGame, Frontend} from './eql'

export default class GameLLC implements Frontend {
    code: string = "GAMELLC";

    SendClientEvent = (eventName: string, gameId: string|null, languageCode: string, data?: any) => {
    }

    ApplyLaunchParams = (game: EQLGame):EQLGame => {
        // TODO: need to dynamically load the UAE SDK here, could potentially load SDK version from session to allow upgrading of SDK without release

        game.STATE.extraData = '{"version": "1.0.0", "versionCode": "100", "product": "603", "platform": "7"}'; 

        const app = new window.$AppSDK.spa({
            container: 'root',
            id: 'EQL'
        });

        const busApp = new window.$AppSDK.bus({
            os: 1,       
            platform: '7'
        });
                  
        const loaded = () => {
            console.log('Loading UAE SDK completed.');

            if(busApp!=null) {
                busApp.getClientInfo(
                    {},
                    function (responseData:any) {
                      console.log('Received getClientInfo response:', responseData);
                      game.STATE.extraData = JSON.stringify(responseData);
                    }
                  );
            }
            };
            
        app.onload = loaded.bind(this);        
        //app.create(window.location.href);

        return game;
    }
}